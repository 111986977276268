<template>
  <section class="m-aftersale-detail m-scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/icon_back@2x.png" alt="back">
      <img src="@/assets/icon_kefu@2x.png" alt="" @click="$router.push('/en/customer')">
    </div>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`"></div>

    <!-- 订单状态 -->
    <div class="order-status">
      <p>{{order.audit_status_title}}</p>
      <p v-if="order.audit_status == 0">The service order has been successfully applied, please wait patiently for the merchant to review</p>

      <p v-if="order.audit_status == 1 && order.after_sales_status != 3">Application has been approved.</p>

      <p v-if="order.audit_status == 1 && order.after_sales_status == 3">
        The merchant has refunded it and it is expected to arrive in <span> 3 to 5 working days</span>
      </p>

      <p v-if="order.audit_status == 2">{{order.refuse_reason}}</p>
    </div>

    <div class="logistics">
      <div class="number van-hairline--bottom">
        <p>After-sales number</p>
      </div>
      <div  class="order-title van-hairline--bottom">
        <p>{{order.title}}</p>
        <!-- <span>Copy</span> -->
      </div>
    </div>

    <!-- 退款金额 -->
    <div class="price-info" v-if="step == 4">
        
        <div class="price-total van-hairline--bottom">
          <span>Total refund</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>234.00</span></p>
        </div>
        <div class="price-cell">
          <span>Commodity price</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>34.00</span></p>
        </div>
        <div class="price-cell">
          <span>Tax refund amount</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>200.00</span></p>
        </div>
    </div>

    <!-- 退款方式 -->
    <div class="price-info" v-if="step == 4">
        
        <div class="price-total van-hairline--bottom">
          <span>Refund method</span>
          <p><span>Original payment return</span></p>
        </div>
        <div class="return-tips">
          *Original payment return: online payment only supports original return
        </div>
    </div>

    <!-- 发货单信息 -->
    <div class="return-address" v-if="step == 3">
      <p class="van-hairline--bottom">Delivery information</p>
      <div class="info-cell">
        <span>Tracking number</span>
        <p>1235589898989898999</p>
      </div>
      <div class="info-cell">
        <span>Logistics company</span>
        <p>Logistics company name</p>
      </div>
      <div >
         <span @click="$router.push({path:'/en/aftersale/logistics/1'})" style="color:#ED2A24">Modify</span>
      </div>
    
    </div>

    <div class="return-address" v-if="step != 1">
      <p class="van-hairline--bottom">Product Return Address</p>
      <p>
        <img src="@/assets/aftersale/icon_address.png" alt="address">
        <span>MoobyYoho</span>
        <span>7786806480</span>
      </p>
      <p style="padding-bottom:15px">11121 Horseshoe Way Unit 133，Richmond，BC V7A 5G7</p>
      <!-- <div >
         <span>Copy address</span>
         <span v-if="step == 2">Cancel service</span>
         <span v-if="step == 2" class="active-span" @click="$router.push({path:'/en/aftersale/logistics/1'})">Fill in</span>
      </div> -->
    
    </div>



    <!-- 商品信息 -->
    <ul class="goods-list m-padding">
      <li class="goods-title"><router-link to>Order details</router-link></li>
      <li class="goods-item">
        <div class="goods-merchant">
          <div @click="$router.push(`/en/merchant/index/${order.shops_id}`)">
            <img src="@/assets/public/icon_dianpu@2x.png" alt="store">
            <span>{{order.shops_title}}</span>
          </div>
        </div>
        <div class="item-wrap" v-for="i in order.order_goods_all" :key="i.order_goods.goods_id">
            <div class="avatar"><img :src="i.goods_sku_pic_url" alt="img"></div>
            <div class="info">
              <p class="van-ellipsis-l2">{{i.goods_ename}}</p>
              <span>{{i.sku_ename}}</span>
              <p>
                <span>{{order.order_logistics.country == 507 ? 'US$':'C$'}}{{(i.goods_price / i.after_sales_number).toFixed(2)}}</span>
                <span>x{{i.after_sales_number}}</span>
              </p>
            </div>
          </div>
      </li>
    </ul>


    <!-- 订单信息 -->
    <div class="order-info m-padding">
      <div class="info-title">Service details</div>
      <div class="info-cell">
        <span>Service type</span>
        <p>{{modeList[order.mode - 1]}}</p>
      </div>
      <div class="info-cell">
          <span>Product Price</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_goods_price}}</span></p>
      </div>
      <div class="info-cell">
          <span>Tax</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_tax_price}}</span></p>
      </div>
      <div class="info-cell">
          <span>Credits in Wallet</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_wallet_price}}</span></p>
      </div>
       <div class="info-cell">
          <span>Refund amount</span>
          <p><span> {{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_price}}</span></p>
        </div>
      <div class="info-cell">
        <span>Reason</span>
        <p>{{order.reason}}</p>
      </div>
      <div class="info-cell">
        <span>Refund method</span>
        <p>Original payment refund</p>
      </div>
      <div class="info-cell">
        <span>Application time</span>
        <p>{{order.created_at}}</p>
      </div>
    </div>

      <div class="order-info m-padding">
      <div class="info-title">Contact person</div>
      <div class="info-cell ">
        <span>Name</span>
        <p>{{ order.order_logistics.name + order.order_logistics.surname || 'N/A'}}</p>
      </div>
      <div class="info-cell ">
        <span>Contact details</span>
        <p>{{order.order_logistics.tel || order.order_logistics.email || 'N/A'}}</p>
      </div>

    </div>

    <!-- 为您推荐 -->
    <m-divider title="Recommended" class="recomnd"></m-divider>
    <goods-columns @cartClick="handleCart" :data="remGoodsList"></goods-columns>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>
    
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
      <CartFlex v-if="start" />
    </transition>

  </section>
</template>

<script>
import MDivider     from '@/components/en/m-divider.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'

import { getOrderRemGoodsList } from '@/api/en/order.js'
export default {
  name:'AftersaleDetail',
  components:{ MDivider, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop: 0,
      step: 2,
      data: 8,
      skuModal: false,
      start: false,
      start_dom: null,
      remGoodsList: [],
      goodsId: '',
      loading: false,
      islock: false,
      page: 1,
      order: {},
      modeList: ['Refunds', 'Missing Items', 'Exchange', 'Only refund']
    }
  },
  created(){
    this.order = JSON.parse(sessionStorage.getItem('aftersale') || '')
    console.log(this.order)
    this.getOrderRemGoodsListHandle()
  },
  methods:{
     // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getOrderRemGoodsListHandle()
        }
        this.islock = true
      }
    },
    // 返回上一页
    handleBack(){
      this.$router.go(-1)
    },
    // 完善发货单
    handleComplete(){
      this.$router.push({path:`/en/aftersale/logistics/1`})
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getOrderRemGoodsListHandle() {
      // 获取自定义为你推荐
      this.islock = true
      this.loading  = true
      getOrderRemGoodsList({page: this.page, goods_id: this.$route.params.id}).then(res => {
        this.loading  = false
        if(res) {
          this.remGoodsList = this.remGoodsList.concat(res.data.data)
          this.islock   = false
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';
</style>